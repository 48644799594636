body {
  margin: 0;
  font-family: 'Times New Roman' , sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {scroll-behavior: smooth;}
@media screen and (max-width: 680px) {
  html {
    font-size: 62.5%;
  }
  img {
    max-width: 90vw;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: relative;
}

h1,h2,h3,h4,h5,h6 {
  font-family: 'Times New Roman', Times, serif !important;
}