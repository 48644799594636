#trangchu-bangkhen {
    height: 30vh;
    width: 90%;
    margin: 3rem auto -2rem;
    
    .carousel {
        height: 100%;
        margin: auto 0;
    }
    #carousel-container {
        height: 100% !important;
        padding: 2rem;
    }
    .carousel-item {
        padding: 1rem;
        display: flex;
        align-items: center;
    }
    .carousel-img {
        height: 25vh !important;
        width: 18vw;
    }
}
@media screen and (max-width: 1370px) {
    #trangchu-bangkhen {
        width: 100%;
    }
}
@media screen and (max-width: 900px) {
    #trangchu-bangkhen {
        margin-bottom: -5rem;
        .carousel-img {
            height: 20vh !important;
        }
    }
}
@media screen and (max-width: 678px) {
    #trangchu-bangkhen {
        margin-bottom: -10rem;
        .carousel-img {
            height: 15vh !important;
        }
    }
}
@media screen and (max-width: 500px) {
    #trangchu-bangkhen {
        height: 25vh;
        .carousel-img {
            height: 10vh !important;
        }
    }
}