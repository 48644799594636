#trangchu-sanpham {
    min-height: 90vh;
    width: 90%;
    margin: 0 auto;

    .sanpham-container {
        width: 90%;
        height: 90%;
        padding:0 5rem;
        margin: 3rem auto;
        display: grid;
        grid-template-columns: repeat(4, 20%);
        grid-template-rows: auto;
        grid-row-gap: 3rem;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
    }
    #xemsp-d {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;

        #xemsp-btn {
            padding: 1rem 5rem;
            border: 0;
            outline: none;
            background: linear-gradient(90deg,#f05945ee,#d10fa1,#f14668);
            color: white;
            font-size: 1.3rem;
            font-weight: bold;
            transition: all ease-in-out .3s;
            border-radius: 5px;
            &:hover {
                transform: translateY(-10%);
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.63);
            }
        }
    }
    .sanpham-card {
        display: flex;
        flex-direction: column;
        width: 100%;
        // height: 24rem;
        aspect-ratio: 4/3;
        align-items: center;
        padding: 1rem;
        color: teal;
        transition: all ease-in-out .4s;
        // margin-top: 2rem;
        cursor: pointer;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
        
        &:hover {
            transform: scale(1.05);
        }
        h4 {
            padding-top: 0;
            margin: 1rem 0 0;
            font-size: 1rem;
            text-transform: uppercase;
        }

        .sanpham-card-img {
            height: 100%;
            width: 100%;
            padding: 0;
            position: relative;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            &::after {
                position: absolute;
                content: "";
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                background: url('../../../../../public/images/sp-bg.jpg');
                background-size: contain;
                background-position: center;
                opacity: .5;
                filter: blur(3px);
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .sanpham-container {
            grid-template-columns: repeat(3,33%) !important;
            justify-content: space-between ;
        }
    }
    @media screen and (max-width: 1150px) {
        .sanpham-container {
            grid-template-columns: repeat(2,40%) !important;
            justify-content: space-around;
            grid-row-gap: 1rem !important;
        }
    }
    @media screen and (max-width: 800px) {
        .sanpham-container {
            grid-template-columns: repeat(1,70%) !important;
            padding: 3rem 0rem !important;
            grid-row-gap: 1rem !important;
        }
    }
    @media screen and (max-width: 520px) {
        .sanpham-container {
            grid-template-columns: repeat(1,95%) !important;
            .sanpham-card {
                h4 {
                    font-size: 1.3rem !important;
                }
            }
        }
    }
    @media screen and (max-width: 400px) {
        .sanpham-container {
            grid-template-columns: repeat(1,100%) !important;
        }
    }
}