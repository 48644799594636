$color-primary: #c69963;
// $color-primary-dark: #B28451;
$color-primary-dark: teal;

$color-secondary: #101d2c;

$color-grey-light-1: #f9f7f6;
$color-grey-light-2: #aaa;

$color-grey-dark-1: #54483A;
$color-grey-dark-2: #6D5D4B;

// FONT VARIABLES
$font-primary: 'Nunito', sans-serif;
$font-display: 'Josefin Sans', sans-serif;

// responsive-breakpoint
$bp-largest : 75em;
$bp-large: 62.5em;
$bp-medium: 50em;
$bp-small: 37.5em;
.footer {
    // background-color: #101d2c;
    background-image: url('../../../public/images/header-bg.jpg');
    color: $color-primary-dark !important;

    &_container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 3rem !important;
        background-color: rgba(255, 255, 255, 0.582);
    }
    &__info {
        display: flex;
        flex-direction: column;
    }
    &_link {
        text-decoration: none;
        color: inherit;
        &:hover {
            transform: scale(1.05);
            color: orange;
        }
    }
    .footer-span {
        background-color: rgba(255, 255, 255, 0.582);
        color: teal;
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 0.8rem;
    }
    &_list {
        @media only screen and (max-width: 600px) {
            display: none;
        }
    }

}