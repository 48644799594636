#login-container {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-form {
        width: 50%;
        min-height: 50%;
        background-color: rgba(81, 158, 245, 0.562);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0;
        box-shadow: 5px 5px 1rem rgba(0, 0, 0, 0.733);
        .logo-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 2rem;
            h1 {
                margin: 0;
            }
        }
        .login-form-group {
            width: 40%;
            display: flex;
            flex-direction: column;
            margin: .5rem 0;

            input {
                border: none;
                outline: none;
                padding: .5rem;
                border-radius: 3px;
                transition: all ease-in-out .5s;
                &:focus {
                    box-shadow: 0 0 10px lightgreen;
                }
            }
        }
        .form-submit {
            width: 40%;
            padding: 2rem 0;
            display: flex;
            justify-content: center;

            .submit-btn {
                padding: .8rem 2.5rem;
                background-color: orangered;
                color: white;
                font-weight: bold;
                border: none;
                outline: none;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 3px 3px 5px grey;
                &:hover {
                    background-color: darken(orangered, 5);
                }
            }
        }
    }
    @media screen and (max-width: 1000px) {
        .login-form .login-form-group {
            width: 80%;
        }
    }
    @media screen and (max-width: 690px) {
        .login-form {
            width: 80%;
        }
    }
}