#gioithieu-page-container {
    padding-bottom: 3rem;
}

#gioithieu-2-section {
    padding: 2rem 0;
    width: 80%;
    margin: 0 auto;

    .gioithieu-detail-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        width: 100%;
    }
    .gioithieu-detail-d {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-image: url('../../../public/images/liehe-bg.jpg');
        background-position: center;
        background-size: cover;

        .gioithieu-detail-p {
            height: 100%;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.747);
            align-content: center;
            font-size: 1.6rem;
        }
    }

    .gioithieu-detail-video {
        width: 30%;
    }
    @media screen and (max-width: 1000px) {
        .gioithieu-detail-container {
            flex-direction: column;
            justify-content: center !important;
            .gioithieu-detail-d {
                margin-bottom: 3rem;
            }
            .gioithieu-detail-d, .gioithieu-detail-video {
                width: 100%;
            }
        }
    }
    @media screen and (max-width:440px) {
        .gioithieu-detail-container {
            .gioithieu-detail-video {
                iframe {
                    width: 100%;
                }
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    #gioithieu-2-section {
        padding-bottom: 0;
    }
}