.header-container {
    background-image: url("./../../../public/images/header-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}
.header {
    background-color: rgba(32, 178, 171, 0.767);
    width: 100%;
    padding: .5rem;
}

.social-contact {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    color: white;
    font-size: .9em;
    padding-right: 5rem;

    .header-phone-box, .header-social-box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-right: 1.5rem;
    }
    .zalo {
        height: 1.3rem;
        margin-left: .5rem;
        margin-top: 4px;
    }
}

.header-main {
    display: flex;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.74);

    .header-h4 {
        margin: 0;
        margin-bottom: 1rem;
        font-size: 1em;
        text-align: center;
        color: teal;
    }
    .header-h1 {
        margin: 0;
        text-align: center;
        font-size: 2em;
        color: teal;
    }
    .header-logo {
        height: 5.5rem;
        margin-right: 2rem;
        transition: all ease-in-out 0.3s;
        &:hover {
            transform: scale(1.1);
        }
    }
    .header-main-intro {
        display: flex;
        width: 50%;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    .header-main-nav {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
    }
    .header-dropdown-menu {
        min-width: 100%;
        width: 10rem;
        border: none !important;
        box-shadow: 1px 1px .3rem gray !important;
        // background-color: rgba(238, 164, 67, 0.616) !important;
        // background-color: #86e5ffb2 !important;
        background-color: lightgoldenrodyellow !important;
        .menu-item-text {
            display: flex;
            color: lightseagreen;
            align-items: center;
        }
    }
    .ui.dropdown .menu > .item {
        padding: 1.5rem 2rem !important;
        font-size: 1.2rem;
        // color: #e49e48;
        // color: #FF8E9E !important;
        color: lightseagreen;
        text-shadow: 0 0 3px white;
        &:first-child {
            border-bottom: 1px solid rgba(0, 0, 0, 0.062);
        }
    }
    .sub-menu-icon {
        width: 1.8rem !important;
        height: 1.8rem !important;
        margin-right: 1rem;
    }

    @media screen and (max-width: 680px) {
        .ui.dropdown .menu > .item {
            font-size: 1.5rem !important;
        }
    }
    @media screen and (max-width: 400px) {
        .ui.dropdown .menu > .item {
            font-size: 1.15rem !important;
        }
    }
    .ui.pointing.dropdown > .menu::after {
        display: none !important;
    }
    .header-menu-item {
        list-style: none;
        display: inline-block;
        padding: 1rem 2rem;
        font-size: 1.3em;
        font-weight: bold;
        color: lightseagreen;
        transition: all ease-in-out 0.2s;

        &:hover, &:active, &.active {
          border-bottom: 2px solid orange;  
          color: orange;
        }
    }
    @media screen and (max-width: 420px) {
        .header-menu-item {
            font-size: 1.1rem !important;
        }
    }
    .sign-off-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 2rem;
        width: 2rem;
        font-size: 1.3rem;
        color: red;
        outline: none;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 2px 2px 3px grey; 
        transition: transform ease-in-out .2s;
        &:hover {
            background-color: transparent;
        }
    }
}
@media screen and (max-width: 1700px) {
    .header-menu-item {
        font-size: 1rem !important;
        padding: .5rem 1rem !important;
    }
}
@media screen and (max-width: 1130px) {
    .header-main-intro {
       justify-content: left !important;
       width: 40% !important;
    }
    .header-menu-item {
        font-size: 1rem !important;
        padding: .5rem 1rem !important;
    }
}
@media screen and (max-width: 1030px) {
    .header-main-intro {
       .header-h4 {
         font-size: .9rem !important;
       }
       .header-h1 {
        font-size: 1.6rem !important;
       }
    }
    .header-menu-item {
        font-size: .9rem !important;
        padding: .5rem 1rem !important;
    }
}
@media screen and (max-width: 940px) {
    .header-main {
        flex-direction: column;
        .header-main-intro {
            width: 100% !important;
            justify-content: center !important;
            margin-bottom: 2rem;
        }
        .header-main-nav {
            width: 100% !important;
            justify-content: space-evenly;
            .header-menu-item {
                font-size: 1rem !important;
            }
        }
    }
}
@media screen and (max-width: 680px) {
    .header-main-intro {
        .header-h4 {
          font-size: 1.5rem !important;
        }
        .header-h1 {
         font-size: 2rem !important;
        }
     }
    .header-menu-item {
        font-size: 1.5rem !important;
        padding: .2rem .5rem !important;
    }
}
@media screen and (max-width: 400px) {
    .header-main-intro {
        .header-h4 {
          font-size: 1.1rem !important;
        }
        .header-h1 {
         font-size: 1.5rem !important;
        }
     }
    .header-menu-item {
        font-size: 1.1rem !important;
        padding: .2rem .5rem !important;
    }
}
@media screen and (max-width: 400px) {
    .header-main {
        padding: 20px 10px !important;
    }
    .header .header-globe-icon {
        display: none;
    }
    .header .header-icon {
        margin: 0 1rem;
    }
}
