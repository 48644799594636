#them-bai-viet-container {
    width: 100%;
    padding-bottom: 3rem;
    
    .them-bai-viet-main {
        width: 1100px;
        margin: 4rem auto;

        h1 {
            margin-bottom: 2rem;
            color: teal;
        }
    }
    
    #them-bai-viet-form {
        display: flex;
        flex-direction: column;
        #title {
            padding: 5px 1rem;
            outline: none;
        }

        .content-editor-container {
            margin: 1rem 0;
            display: flex;
            flex-direction: column;

            #editor-container {
                .ql-container {
                    min-height: 25rem;
                    height: 25rem;
                    max-height: 40rem;
                    overflow-y: auto;
                }
            }

            .form-label {
                font-size: 1.2rem;
                margin-bottom: 5px;
                font-weight: bold;
            }
        }
        .content-editor-btn-container {
            display: flex;
            justify-content: flex-end;
            .editor-btn {
                width: 10rem;
                padding: .5rem;
                margin: 0 5px;
                color: white;
                border: none;
                outline: none;
                cursor: pointer;
                border-radius: 5px;
                font-weight: 400;
            }
            .editor-btn.cancel {
                background-color: red !important;
                text-align: center;
            }
            .editor-btn.save {
                background-color: teal !important;
            }

        }

    
    }

    
}
