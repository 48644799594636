 #trangchu-lienhe {
    width: 100vw;
    margin: 4rem 0 0;
    background-image: url('../../../../../public/images/flw.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: lighten !important;
    
    &::before {
        width: 100%;
        height: 100%;
        content: ' ';
        background-color: rgba(122, 117, 117, 0.74);
    }
 }
 .lienhe__container {
    display: flex;
    width: 70%;
    height: 100%;
    padding: 3rem;
    justify-content: space-around;
    align-items: center;
    overflow: -moz-hidden-unscrollable;
    margin:0 auto;
  
    h1 {
      text-transform: uppercase;
      font-family: sans-serif;
      font-size: 1.2em !important;
    }
  }
  @media screen and (max-width: 1670px) {
    .lienhe__container {
      width: 90%;
    }
  }
  @media screen and (max-width: 1320px) {
    .lienhe__container {
      width: 100%;
    }
    .lienhe-map {
      width: 40%;
      #map-iframe {
        width: 100%;
      }
    }
    .ui.form {
      width: 100%;
    }
  }
  @media screen and (max-width: 900px) {
    .lienhe__container {
      flex-direction: column-reverse;
    }
    .lienhe-map {
      width: 80%;
      #map-iframe {
        width: 100%;
      }
    }
    .lienhe__sub_container {
      width: 80% !important;
      margin-bottom: 3rem;
    }
  }
  @media screen and (max-width: 530px) {
    .lienhe-map {
      width: 100%;
    }
    .lienhe__sub_container {
      width: 100% !important;
    }
  }
  .form {
    font-size: 1em !important;
    .lienhe-input {
        padding: .4rem !important;
    }
    textarea {
      padding: .7rem!important;
      border-radius: 10px !important;
      font-size: .9em !important;
    }
    label {
      color: white !important;
      font-size: .9em !important;
    }
    .field {
      margin-bottom: 1rem !important;
    }
    @media only screen and (max-width: 900px) {
      width: 35vw;
    }
    @media only screen and (max-width: 630px) {
      width: 40vw;
    }
    @media only screen and (max-width: 550px) {
      width: 50vw;
    }
    @media only screen and (max-width: 450px) {
      width: 60vw;
    }
    @media only screen and (max-width: 370px) {
      width: 80vw;
    }
  }

  .lienhe__sub_container {
    border-radius: 5px;
    width: 40%;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    background: linear-gradient(90deg, #f05945ee, #d10fa1);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.637);
    color: white;
  }

  .ui.button.lienhe_submit_btn {
    font-size: 1em !important;
    padding: .5rem 2rem !important;
    float: right;
    border-radius: 10px !important;
    box-shadow: inset 1px 1px 3px white, inset -1px -1px 1px white !important;
  }
  